import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { set } from "lodash";
import { AlertContext } from "../../../Context/AlertManager";
import {
  DUE_PAYER_EMPTY_FORM_ERRORS,
  DuePayerFormError,
  populateFormErrors,
} from "../../../Utils/FormUtils";
import { Member } from "../ManageMembers";
import { on } from "events";

interface RequestBody {
  email: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
}

// Handles the form and creation of a new member.
export default function AddMemberFormModal({
  open,
  setOpen,
  onMemberAdded,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onMemberAdded: (member: Member) => void;
}) {
  const [isCreating, setIsCreating] = React.useState<boolean>(false);
  const { showAlert } = React.useContext(AlertContext);
  const [formErrors, setFormErrors] = React.useState<DuePayerFormError>(
    DUE_PAYER_EMPTY_FORM_ERRORS,
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsCreating(true);
    setFormErrors(DUE_PAYER_EMPTY_FORM_ERRORS);
    const formData = new FormData(event.currentTarget);
    const entries = Object.fromEntries((formData as any).entries());

    const requestBody: RequestBody = {
      email: entries["email"],
      first_name: entries["first-name"],
      last_name: entries["last-name"],
      phone_number: entries["phone"],
    };

    axios
      .post("/due-payers/create/", requestBody)
      .then((response) => {
        onMemberAdded(response.data as Member);
        showAlert(`${requestBody.first_name} created successfully!`, "success");
        handleClose();
      })
      .catch((error) => {
        populateFormErrors(error, formErrors, setFormErrors);
        showAlert("Failed to create member!", "error");
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: onFormSubmit,
        }}
      >
        <DialogTitle>Add Member</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="first-name"
            name="first-name"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            error={formErrors.first_name !== ""}
            helperText={formErrors.first_name}
          />
          <TextField
            required
            margin="dense"
            id="last-name"
            name="last-name"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            error={formErrors.last_name !== ""}
            helperText={formErrors.last_name}
          />
          <TextField
            required
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            error={formErrors.email !== ""}
            helperText={formErrors.email}
          />
          <TextField
            margin="dense"
            id="phone"
            name="phone"
            label="Phone Number"
            type="tel"
            fullWidth
            variant="standard"
            error={formErrors.phone_number !== ""}
            helperText={formErrors.phone_number}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton id="submit" loading={isCreating} type="submit">
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
