import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { AlertContext } from "../../Context/AlertManager";
import { blue } from "@mui/material/colors";
import { Divider } from "@mui/material";

interface DueListInfo {
  pk: string;
  name: string;
  total_amount: number;
  number_of_payments: number;
  number_of_payers: number;
}

const ManageDues: React.FC = () => {
  const [dues, setDues] = useState<DueListInfo[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { showAlert } = useContext(AlertContext);

  const addDue = () => {
    navigate("/create-due");
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleOpen = (due: DueListInfo) => {
    navigate(`/due-details/${due.pk}`);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/dues/get/")
      .then((res) => {
        setDues(res.data);
      })
      .catch((error: any) => {
        showAlert(
          "Something went wrong fetching dues. Please refresh.",
          "error",
        );
        console.error(error.response.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const filteredDues = dues.filter((due) =>
    due.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Stack spacing={4}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "stretch", sm: "center" }}
          spacing={2}
        >
          <TextField
            fullWidth
            id="due-search"
            label="Search Dues"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button id="create-due" variant="outlined" onClick={addDue}>
            Create Due
          </Button>
        </Stack>

        {isLoading ? (
          <Box display="flex" justifyContent="center" padding={2}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box>
            {filteredDues.length > 0 ? (
              filteredDues.map((due) => (
                <Box key={due.pk} sx={{ marginBottom: 3 }}>
                  <Typography
                    className="due-title"
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                  >
                    {due.name}
                  </Typography>
                  <Typography
                    className="due-amount"
                    variant="body1"
                    color="textSecondary"
                    sx={{ marginTop: 0.5 }}
                  >
                    Total Amount: <strong>${due.total_amount}</strong>
                  </Typography>
                  <Typography
                    className="due-info"
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginTop: 0.5 }}
                  >
                    {due.number_of_payments} Payments | {due.number_of_payers}{" "}
                    Members
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ marginTop: 2 }}
                    onClick={() => handleOpen(due)}
                  >
                    View More
                  </Button>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
              ))
            ) : (
              <Typography
                variant="body1"
                color="textSecondary"
                textAlign="center"
              >
                No dues found. Try adjusting your search or create a new due.
              </Typography>
            )}
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default ManageDues;
