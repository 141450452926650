import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Member } from "../../ManageMembers/ManageMembers";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import { AlertContext } from "../../../Context/AlertManager";
import { round } from "lodash";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "800px",
  height: "80%",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "16px",
};

const columns: GridColDef<Member[][number]>[] = [
  {
    field: "first_name",
    headerName: "First name",
    width: 200,
    editable: true,
  },
  { field: "last_name", headerName: "Last name", width: 200, editable: true },
];

interface AddPayersModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: (payers: Member[]) => void;
}

export default function AddPayerModal({
  isOpen: open,
  setIsOpen: setOpen,
  onConfirm,
}: AddPayersModalProps) {
  const [members, setMembers] = React.useState<Member[]>([]);
  const [selectedMembers, setSelectedMembers] = React.useState<Member[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const { showAlert } = React.useContext(AlertContext);

  const handleClose = () => setOpen(false);

  const onSave = () => {
    const ids = new Set(rowSelectionModel);
    const newMembers = members.filter((member) => ids.has(member.id));
    onConfirm(newMembers);
  };

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get("/due-payers/get/")
      .then((res) => {
        setMembers(res.data);
      })
      .catch((err) => {
        showAlert("Failed to fetch members! Try reloading!", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant="h5" gutterBottom>
              Add Member to Due
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={members}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
                loading={isLoading}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
              />
            </Box>
            <Box sx={buttonContainerStyle}>
              <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
                Cancel
              </Button>
              <Button variant="contained" onClick={onSave}>
                Save
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
