type PaymentMethod = {
  key: string;
  label: string;
};

const paymentMethods = [
  { key: "cash", label: "Cash" },
  { key: "check", label: "Check" },
  { key: "bank_transfer", label: "Bank Transfer" },
  { key: "zelle", label: "Zelle" },
  { key: "venmo", label: "Venmo" },
  { key: "paypal", label: "PayPal" },
  { key: "money_order", label: "Money Order" },
  { key: "other", label: "Other" },
];

export default paymentMethods;
