import * as React from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { AlertContext } from "../../Context/AlertManager";
import {
  populateFormErrors,
  USER_EMPTY_FORM_ERRORS,
} from "../../Utils/FormUtils";
import { AuthContext } from "../../Context/AuthManager";
import { LoadingButton } from "@mui/lab";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        GreekMate.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const { showAlert } = React.useContext(AlertContext);
  const { updateToken } = React.useContext(AuthContext);

  const [formErrors, setFormErrors] = React.useState(USER_EMPTY_FORM_ERRORS);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFormErrors(USER_EMPTY_FORM_ERRORS);

    const data = new FormData(event.currentTarget);

    setIsSubmitting(true);
    axios
      .post("/token/", {
        username: data.get("email"),
        password: data.get("password"),
      })
      .then((response) => {
        showAlert("Login successful!", "success");
        const token = response.data.access;
        updateToken(token);
        navigate("/");
      })
      .catch((error) => {
        populateFormErrors(error, formErrors, setFormErrors);
        showAlert("Login failed!", "error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={formErrors.email !== ""}
            helperText={formErrors.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={formErrors.password !== ""}
            helperText={formErrors.password}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <LoadingButton
            id="submit"
            type="submit"
            loading={isSubmitting}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link
                id="signup"
                component={RouterLink}
                to="/signup"
                variant="body2"
              >
                {"Dont have an account? Sign Up."}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
