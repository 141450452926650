import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination"; // Import Pagination
import Title from "./Title";
import PaymentContext from "../Context/PaymentsProvider";

export default function PaymentsTable() {
  const { payments } = React.useContext(PaymentContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Default rows per page

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page change
  };

  // Slice the payments based on current page and rows per page
  const paginatedPayments = payments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <React.Fragment>
      <Title>Recent Payments</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Method</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedPayments.map((record, index) => (
            <TableRow key={record.pk}>
              <TableCell id={`${index + 1}-record-date`}>
                {record.date}
              </TableCell>
              <TableCell
                id={`${index + 1}-record-name`}
              >{`${record.first_name} ${record.last_name}`}</TableCell>
              <TableCell id={`${index + 1}-record-amount`}>
                ${record.amount}
              </TableCell>
              <TableCell id={`${index + 1}-record-description`}>
                {record.description}
              </TableCell>
              <TableCell id={`${index + 1}-record-method`} align="right">
                {record.method}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Pagination controls */}
      <TablePagination
        component="div"
        count={payments.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <Link
        color="primary"
        href="#"
        onClick={(event) => event.preventDefault()}
        sx={{ mt: 3 }}
      >
        See all payments
      </Link>
    </React.Fragment>
  );
}
