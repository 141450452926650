import { Alert, Snackbar } from "@mui/material";
import React, { createContext, useState } from "react";

type AlertStatus = "success" | "error" | "info" | "warning";

interface AlertContextValue {
  showAlert: (message: string, type: AlertStatus) => void;
}

const initialContextValue: AlertContextValue = {
  showAlert: () => {},
};

export const AlertContext =
  createContext<AlertContextValue>(initialContextValue);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<AlertStatus>("success");
  const [isShowingAlert, setIsShowingAlert] = useState<boolean>(false);

  const showAlert = (message: string, type: AlertStatus) => {
    setAlertMessage(message);
    setAlertType(type);
    setIsShowingAlert(true);
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isShowingAlert}
        autoHideDuration={6000}
        onClose={() => setIsShowingAlert(false)}
      >
        <Alert
          id="alert"
          onClose={() => setIsShowingAlert(false)}
          severity={alertType}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};
