import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Notification } from "../Context/AuthManager";
import { Badge, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;

interface NotificationDropdownProps {
  notifications: Notification[];
}

const NotificationDropdown: React.FC<NotificationDropdownProps> = ({
  notifications,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="notifications"
        id="notification-button"
        aria-controls={open ? "notification-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: "white" }}
      >
        <Badge
          id="notif-badge"
          badgeContent={notifications.length}
          color="error"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="notification-menu"
        MenuListProps={{
          "aria-labelledby": "notification-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {notifications.length == 0 ? (
          <MenuItem onClick={handleClose}>
            <Typography variant="body1">You have no notifications.</Typography>
          </MenuItem>
        ) : (
          notifications.map((notification, index) => (
            <MenuItem
              id={`notif-${index + 1}`}
              key={notification.id}
              onClick={handleClose}
            >
              <Typography
                onClick={() => navigate(notification.route)}
                variant="body1"
              >
                {notification.message}
              </Typography>
            </MenuItem>
          ))
        )}
      </Menu>
    </div>
  );
};

export default NotificationDropdown;
