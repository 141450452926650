import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Member } from "../../ManageMembers/ManageMembers";
import { AlertContext } from "../../../Context/AlertManager";

// TODO: Show info alert info to double click to edit if edit is clicked on

const options = ["View Details", "Delete", "Edit"];

interface ActionButtonDropdownProps {
  handleDeleteClick: () => void;
  handleViewDetailsClick: () => void;
}

const ActionButtonDropdown: React.FC<ActionButtonDropdownProps> = ({
  handleDeleteClick,
  handleViewDetailsClick,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { showAlert } = React.useContext(AlertContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleDeleteClick();
    setAnchorEl(null);
  };

  const handleEdit = () => {
    showAlert("Double click on any user to edit!", "info");
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="action-menu-btn"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: "20ch",
            },
          },
        }}
      >
        <MenuItem onClick={handleViewDetailsClick}>View Details</MenuItem>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem id="delete-btn" onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionButtonDropdown;
