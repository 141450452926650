import React from "react";
import {Button, Grid, TextField, Typography} from "@mui/material";
import axios from "axios";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {AlertContext} from "../../../../Context/AlertManager";

interface NameSlideProps {
    handleNext: () => void;
    setTitle: (title: string) => void;
    title: string;
}

const NameSlide: React.FC<NameSlideProps> = ({
                                                 handleNext, setTitle, title,
                                             }: NameSlideProps) => {
    const [titleError, setTitleError] = React.useState<string>("");
    const {showAlert} = React.useContext(AlertContext);

    const handleTitleSubmit = () => {
        if (title === "") {
            setTitleError("The name cannot be empty.");
            return;
        }

        axios
            .post("/due/verify/", {name: title})
            .then((res) => {
                handleNext();
            })
            .catch((err) => {
                if ("name" in err.response.data) {
                    let errorDescription = err.response.data.name[0];
                    errorDescription = errorDescription.charAt(0).toUpperCase() + errorDescription.slice(1);
                    setTitleError(errorDescription);
                }
            });
    };

    return (<Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Whats the name of this Due?</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="name-entry"
                    error={titleError !== ""}
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={title}
                    onChange={(e: any) => setTitle(e.target.value)}
                    helperText={titleError}
                />
            </Grid>
            <Grid item xs={12} sx={{textAlign: "right"}}>
                <Button id="next" variant="contained" color="primary" onClick={handleTitleSubmit}>
                    <NavigateNextIcon/>
                </Button>
            </Grid>
        </Grid>);
};

export default NameSlide;
