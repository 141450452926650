import { AxiosError } from "axios";
import exp from "constants";
import React from "react";

export interface UserFormError {
  email: string;
  password: string;
  confirm_password: string;
}

export interface DuePayerFormError {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export interface AddPaymentModalFormError {
  amount: string;
  memo: string;
  date: string;
}

export const USER_EMPTY_FORM_ERRORS: UserFormError = {
  email: "",
  password: "",
  confirm_password: "",
};

export const DUE_PAYER_EMPTY_FORM_ERRORS: DuePayerFormError = {
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
};

export const ADD_PAYMENT_MODAL_EMPTY_FORM_ERRORS = {
  amount: "",
  memo: "",
  date: "",
};

export const populateFormErrors = <
  T extends UserFormError | DuePayerFormError | AddPaymentModalFormError,
>(
  error: AxiosError,
  formErrors: T,
  setFormErrors: React.Dispatch<React.SetStateAction<T>>,
) => {
  for (const key in error.response?.data as any) {
    if (key in formErrors) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [key]: (error.response?.data as any)[key][0],
      }));
    }
  }
};
