import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PaymentContext, { labelToPeriod } from "../Context/PaymentsProvider";
import { PeriodQueryParam, PeriodLabel } from "../Context/PaymentsProvider";

const SelectPeriod: React.FC = ({}) => {
  const { period, setPeriod } = React.useContext(PaymentContext);

  const handleChange = (event: SelectChangeEvent) => {
    setPeriod(event.target.value as PeriodQueryParam);
  };

  return (
    <FormControl sx={{ m: 1, maxWidth: 130 }} size="small">
      <InputLabel id="period-select-label">Period</InputLabel>
      <Select
        labelId="period-select-label"
        id="period-select"
        value={period}
        label="Period"
        onChange={handleChange}
      >
        {Object.keys(labelToPeriod).map((periodLabel, index) => (
          <MenuItem
            key={index}
            value={labelToPeriod[periodLabel as PeriodLabel]}
          >
            {periodLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectPeriod;
