import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { LineChart, axisClasses } from "@mui/x-charts";
import { ChartsTextStyle } from "@mui/x-charts/ChartsText";
import SelectPeriod from "./SelectPeriod";
import PaymentContext from "../Context/PaymentsProvider";

export default function Chart() {
  const theme = useTheme();
  const { payments } = React.useContext(PaymentContext);

  const date_to_amount = payments.reduce(
    (acc: Record<string, number>, payment) => {
      const date = payment.date;
      const amount = parseFloat(payment.amount);

      if (!acc[date]) {
        acc[date] = amount;
      } else {
        acc[date] += amount;
      }

      return acc;
    },
    {} as Record<string, number>,
  );

  const data = Object.keys(date_to_amount)
    .sort()
    .map((date) => ({
      date: date,
      amount: date_to_amount[date],
    }));

  return (
    <React.Fragment>
      <SelectPeriod />
      <div style={{ width: "100%", flexGrow: 1, overflow: "hidden" }}>
        <LineChart
          dataset={data}
          margin={{
            top: 16,
            right: 20,
            left: 70,
            bottom: 30,
          }}
          xAxis={[
            {
              scaleType: "point",
              dataKey: "date",
              tickNumber: 2,
              tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
            },
          ]}
          yAxis={[
            {
              label: "Payments ($)",
              labelStyle: {
                ...(theme.typography.body1 as ChartsTextStyle),
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
              min: 0,
              max: Math.max(...data.map((d) => d.amount)),
              tickNumber: 3,
            },
          ]}
          series={[
            {
              dataKey: "amount",
              showMark: true,
              color: theme.palette.primary.light,
            },
          ]}
          sx={{
            [`.${axisClasses.root} line`]: {
              stroke: theme.palette.text.secondary,
            },
            [`.${axisClasses.root} text`]: {
              fill: theme.palette.text.secondary,
            },
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: "translateX(-25px)",
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}
