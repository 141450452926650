import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Divider,
} from "@mui/material";
import { Member } from "../../../ManageMembers/ManageMembers";
import { PaymentSchedule } from "../../CreateDue";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";

interface ReviewSlideProps {
  title: string | "";
  payers: Member[];
  schedules: PaymentSchedule[];
  isSubmitting: boolean;
  handlePrevious: () => void;
  handleSubmit: () => void;
  payInFullDiscount: number;
  payInFullDate: string;
}

const ReviewSlide: React.FC<ReviewSlideProps> = ({
  title,
  payers,
  schedules,
  isSubmitting,
  handlePrevious,
  handleSubmit,
  payInFullDiscount,
  payInFullDate,
}: ReviewSlideProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedPayers = payers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const totalAmount = schedules.reduce(
    (acc, schedule) => acc + schedule.amount,
    0,
  );

  return (
    <Box p={3} sx={{ borderRadius: 2 }}>
      <Typography variant="h4" gutterBottom>
        Review Details
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box mb={3}>
        <Typography variant="h6">Title:</Typography>
        <Typography id="title" variant="body1" color="textSecondary">
          {title}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6">Amount:</Typography>
        <Typography id="amount" variant="body1" color="textSecondary">
          ${totalAmount}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6">Payers:</Typography>
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table size="small" aria-label="payers table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedPayers.map((payer) => (
                <TableRow key={payer.id}>
                  <TableCell>
                    {payer.first_name} {payer.last_name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={payers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
      <Box mb={3}>
        <Typography variant="h6">Dates and Amounts:</Typography>
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table size="small" aria-label="schedules table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedules.map((schedule, index) => (
                <TableRow key={index}>
                  <TableCell id={`${index}-date`}>
                    {dayjs(schedule.date).format("MMMM D, YYYY")}
                  </TableCell>
                  <TableCell id={`${index}-amount`}>
                    ${schedule.amount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {payInFullDiscount > 0 && (
        <Box mb={3}>
          <Typography variant="h6">Pay In Full Discount</Typography>
          <Typography variant="body1">
            Members will pay $
            {(totalAmount * (1 - payInFullDiscount)).toFixed(2)} if paid in full
            by {dayjs(payInFullDate).format("MMMM D, YYYY")}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<NavigateBeforeIcon />}
          onClick={handlePrevious}
          sx={{ marginRight: 1 }}
          disabled={isSubmitting}
        ></Button>
        <LoadingButton
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSubmit}
          loading={isSubmitting}
          id="submit"
        >
          Confirm and Save
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ReviewSlide;
