import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import dayjs from "dayjs";
import NameSlide from "./Slides/Name/NameSlide";
import ScheduleSlide from "./Slides/Schedules/ScheduleSlide";
import AddPayersSlide from "./Slides/Payers/AddPayers";
import ReviewSlide from "./Slides/Review/ReviewSlide";
import { Member } from "../ManageMembers/ManageMembers";
import axios from "axios";
import { AlertContext } from "../../Context/AlertManager";
import DiscountSlide from "./Slides/Discounts/DiscountSlide";

export interface PaymentSchedule {
  date: string;
  amount: number;
  memo: string;
}

interface DueRequestBody {
  name: string;
  payment_dates: string[];
  payment_amounts: number[];
  payment_memos: string[];
  payers: number[];
  pay_in_full_date?: string;
  pay_in_full_discount?: number;
}

export interface ScheduleManager {
  updateAmountForSchedule: (index: number, amount: number) => void;
  updateDateForSchedule: (index: number, date: string) => void;
  updateMemoForSchedule: (index: number, date: string) => void;
  addDate: () => void;
  deleteDate: (index: number) => void;
}

const CreateDue: React.FC = () => {
  const [title, setTitle] = useState<string>("");
  const [paymentSchedules, setPaymentSchedules] = useState<PaymentSchedule[]>(
    [],
  );
  const [payInFullDate, setPayInFullDate] = useState<string>(
    dayjs().format("YYYY-MM-DD"),
  );
  const [payInFullDiscount, setPayInFullDiscount] = useState<number>(0);

  const [payers, setPayers] = useState<Member[]>([]);
  const [slide, setSlide] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();
  const { showAlert } = React.useContext(AlertContext);

  const handleNext = () => setSlide(slide + 1);
  const handlePrevious = () => setSlide(slide - 1);

  const handleAddPayer = () =>
    setPayers([
      ...payers,
      {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        id: -1,
      },
    ]);
  const handleRemovePayer = (id: number) =>
    setPayers(payers.filter((payer) => payer.id !== id));

  const handleSubmit = () => {
    // TODO: Add confirmation modal.
    // Something along the lines of you cant change anything except adding or removing members.....

    setIsSubmitting(true);
    const due: DueRequestBody = {
      name: title,
      payment_dates: paymentSchedules.map((schedule) => schedule.date),
      payment_amounts: paymentSchedules.map((schedule) => schedule.amount),
      payment_memos: paymentSchedules.map((schedule) => schedule.memo),
      payers: payers.map((payer) => payer.id),
    };

    if (payInFullDiscount != 0) {
      due["pay_in_full_date"] = payInFullDate;
      due["pay_in_full_discount"] = payInFullDiscount;
    }

    console.log(due);

    axios
      .post("/due/create/", due)
      .then((res) => {
        navigate("/manage-dues");
      })
      .catch((err) => {
        showAlert("Failed to create due!", "error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const scheduleManager: ScheduleManager = {
    updateAmountForSchedule: (index: number, amount: number) => {
      const newPaymentSchedules = [...paymentSchedules];
      newPaymentSchedules[index].amount = amount;
      setPaymentSchedules(newPaymentSchedules);
    },
    updateDateForSchedule: (index: number, date: string) => {
      const newPaymentSchedules = [...paymentSchedules];
      newPaymentSchedules[index].date = date;
      setPaymentSchedules(newPaymentSchedules);
    },
    updateMemoForSchedule: (index: number, memo: string) => {
      const newPaymentSchedules = [...paymentSchedules];
      newPaymentSchedules[index].memo = memo;
      setPaymentSchedules(newPaymentSchedules);
    },
    addDate: () => {
      setPaymentSchedules([
        ...paymentSchedules,
        {
          date: dayjs().format("YYYY-MM-DD"),
          amount: 0,
          memo: "",
        },
      ]);
    },
    deleteDate: (index: number) => {
      const newPaymentSchedules = [...paymentSchedules];
      newPaymentSchedules.splice(index, 1);
      setPaymentSchedules(newPaymentSchedules);
    },
  };

  const steps = ["Title", "Payments", "Members", "Discounts", "Review"];

  return (
    <Grid container sx={{ padding: 2 }} spacing={2}>
      <Grid item xs={12}>
        <Stepper activeStep={slide} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            {slide === 0 && (
              <NameSlide
                handleNext={handleNext}
                setTitle={setTitle}
                title={title}
              />
            )}

            {slide === 1 && (
              <ScheduleSlide
                paymentSchedules={paymentSchedules}
                scheduleManager={scheduleManager}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
              />
            )}

            {slide === 2 && (
              <AddPayersSlide
                payers={payers}
                setPayers={setPayers}
                handleRemovePayer={handleRemovePayer}
                handleAddPayer={handleAddPayer}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
              />
            )}

            {slide === 3 && (
              <DiscountSlide
                payInFullDate={payInFullDate}
                payInFullDiscount={payInFullDiscount}
                setPayInFullDate={setPayInFullDate}
                setPayInFullDiscount={setPayInFullDiscount}
                totalAmount={paymentSchedules.reduce(
                  (a, payment) => a + payment.amount,
                  0,
                )}
                handleNext={handleNext}
              />
            )}

            {slide === 4 && (
              <ReviewSlide
                title={title}
                payers={payers}
                schedules={paymentSchedules}
                handlePrevious={handlePrevious}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                payInFullDiscount={payInFullDiscount}
                payInFullDate={payInFullDate}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CreateDue;
