import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { AlertContext } from "../../../Context/AlertManager";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box } from "@mui/material";
import {
  ADD_PAYMENT_MODAL_EMPTY_FORM_ERRORS,
  AddPaymentModalFormError,
  populateFormErrors,
} from "../../../Utils/FormUtils";
import dayjs, { Dayjs } from "dayjs";

interface AddPaymentObligationFormModalProps {
  isOpen: boolean;
  handleClose: () => void;
  name: string;
  id: string;
  addPayment: (payment: any) => void;
}

export default function AddPaymentObligationFormModal({
  isOpen,
  handleClose,
  name,
  id,
  addPayment,
}: AddPaymentObligationFormModalProps) {
  const [isCreating, setIsCreating] = React.useState<boolean>(false);
  const { showAlert } = React.useContext(AlertContext);
  const [date, setDateValue] = React.useState<string>(
    dayjs().format("YYYY-MM-DD"),
  );
  const [amount, setAmount] = React.useState<number>(0);
  const [memo, setMemo] = React.useState<string>("");
  const [formErrors, setFormErrors] = React.useState<AddPaymentModalFormError>(
    ADD_PAYMENT_MODAL_EMPTY_FORM_ERRORS,
  );

  const handleSubmit = async () => {
    setFormErrors(ADD_PAYMENT_MODAL_EMPTY_FORM_ERRORS);
    setIsCreating(true);

    const formData = {
      payer: id,
      amount: amount,
      date: date,
      memo,
    };

    try {
      const response = await axios.post("/payments/create/", formData);
      showAlert("Payment created.", "success");
      addPayment(response.data);
      handleClose();
    } catch (error: any) {
      populateFormErrors(error, formErrors, setFormErrors);
      showAlert("Failed to create payment.", "error");
    } finally {
      setIsCreating(false);
    }
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setAmount(0);
      return;
    }

    const amt = parseInt(event.target.value);

    if (isNaN(amt) || amt < 0) {
      return;
    }
    setAmount(amt);
  };

  const handleMemoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMemo(event.target.value);
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (!date) return;
    setDateValue(date.format("YYYY-MM-DD"));
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: { padding: 3, borderRadius: 2, maxWidth: "500px" }, // More padding and width control
        }}
      >
        <DialogTitle>Add Payment For {name}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <TextField
              sx={{ mt: 2 }}
              required
              id="amount"
              label="Amount"
              type="text"
              fullWidth
              variant="outlined"
              value={amount}
              onChange={handleAmountChange}
              error={formErrors.amount !== ""}
              helperText={formErrors.amount}
            />
            <DatePicker
              label="Due Date"
              onChange={(newDate) => handleDateChange(newDate)}
              slotProps={{
                textField: {
                  id: "date",
                  error: formErrors.date !== "",
                  helperText: formErrors.date,
                },
              }}
              defaultValue={dayjs()}
            />
            <TextField
              id="memo"
              label="Memo"
              type="text"
              fullWidth
              variant="outlined"
              value={memo}
              multiline={true}
              rows={3}
              onChange={handleMemoChange}
              error={formErrors.memo !== ""}
              helperText={formErrors.memo}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", padding: 2 }}>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <LoadingButton
            id="submit"
            loading={isCreating}
            onClick={handleSubmit}
            variant="contained"
          >
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
