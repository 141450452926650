import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Fade,
  Divider,
} from "@mui/material";
import { Payment } from "../members/PaymentPortal/PaymentPortal";
import { AlertContext } from "../../Context/AlertManager";
import axios from "axios";
import { CheckCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Member } from "../ManageMembers/ManageMembers";

type ConfirmationType = "payment" | "due";

// ConfirmationRequest may refer to a due or a payment!
interface ConfirmationRequest {
  amount: string;
  pk: string;
  method: string;
  message: string;
  payer: Member;
  type: ConfirmationType;
}

const ConfirmPayments: React.FC = () => {
  const [requests, setRequests] = React.useState<ConfirmationRequest[]>([]);
  const { showAlert } = useContext(AlertContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const res = await axios.get("/payments/confirmations/");
        const data = res.data;
        setRequests(data);
      } catch (error) {
        console.error(error);
        showAlert("Failed to fetch confirmation requests", "error");
      }
    };

    fetchRequests();
  }, []);

  function handleApprove() {
    if (!currentRequest) {
      return;
    }

    const submitApprove = async () => {
      try {
        await axios.patch(`/payments/${currentRequest.pk}/status/`, {
          status: "PAID",
          type: currentRequest.type,
          payer: currentRequest.payer.id,
        });

        setRequests((prev) => prev.slice(1));
      } catch (e) {
        console.error(e);
        showAlert(
          "Failed to approve payment. Please try again later.",
          "error",
        );
      }
    };

    submitApprove();
  }

  function handleDeny() {
    if (!currentRequest) {
      return;
    }

    const submitDeny = async () => {
      try {
        await axios.patch(`/payments/${currentRequest.pk}/status/`, {
          status: "UNPAID",
          type: currentRequest.type,
          payer: currentRequest.payer.id,
        });
        setRequests((prev) => prev.slice(1));
      } catch (e) {
        console.error(e);
        showAlert("Failed to deny payment. Please try again later.", "error");
      }
    };

    submitDeny();
  }

  const currentRequest = requests.length > 0 ? requests[0] : null;

  return currentRequest ? (
    <Fade in={true} timeout={500} key={currentRequest.pk}>
      <Card elevation={0} sx={{ maxWidth: 500, margin: "auto", mt: 5 }}>
        <CardContent>
          <Typography
            id="method-title"
            variant="h6"
            align="center"
            gutterBottom
          >
            Confirm{" "}
            {currentRequest.type === "payment" ? "Payment" : "Pay in Full"}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body2" align="center" color="textSecondary">
            {requests.length} payments remaining
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography id="member-name" variant="body1" align="center">
            {currentRequest.payer.first_name} {currentRequest.payer.last_name}
          </Typography>
          <Typography
            id="payment-amount"
            variant="h5"
            align="center"
            sx={{ mt: 1, mb: 1 }}
          >
            ${currentRequest.amount}
          </Typography>
          <Typography
            id="payment-method"
            variant="body2"
            align="center"
            color="textSecondary"
          >
            {currentRequest.method}
          </Typography>
          <Divider sx={{ my: 2 }} />

          {currentRequest.message && (
            <>
              <Typography variant="subtitle1" align="center" gutterBottom>
                Message
              </Typography>
              <Typography
                id="payment-message"
                variant="body2"
                align="center"
                color="textSecondary"
              >
                {currentRequest.message}
              </Typography>
            </>
          )}

          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={6}>
              <Button
                id="approve-btn"
                onClick={handleApprove}
                variant="outlined"
                color="success"
                fullWidth
              >
                Approve
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                id="deny-btn"
                onClick={handleDeny}
                variant="outlined"
                color="error"
                fullWidth
              >
                Deny
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fade>
  ) : (
    <Fade in={true} timeout={500}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="50vh"
        textAlign="center"
      >
        <CheckCircleOutline sx={{ fontSize: 50, color: "#4caf50", mb: 2 }} />
        <Typography variant="h5" gutterBottom>
          You're All Caught Up!
        </Typography>
        <Typography
          id="done-text"
          variant="body1"
          color="textSecondary"
          sx={{ mb: 2 }}
        >
          You have no payments to confirm.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
        >
          Back to Dashboard
        </Button>
      </Box>
    </Fade>
  );
};

export default ConfirmPayments;
