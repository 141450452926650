import React, { useContext, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Container,
  Divider,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import { AlertContext } from "../../../Context/AlertManager";
import PaymentChoiceModal from "./Components/PaymentChoiceModal";
import {
  ConfirmationPaymentModal,
  PaymentType,
} from "./Components/ConfirmationPaymentModal";

interface Props {}

export interface PortalResponse {
  payer_name: string;
  dues: DuePaymentInfo[];
  other_payments: Payment[];
}

interface DuePaymentInfo {
  name: string;
  pay_in_full_date: string;
  pay_in_full_discount: number;
  payments: Payment[];
  can_pay_in_full: boolean;
  pk: string;
}

export interface Payment {
  due: string;
  memo: string;
  amount: string;
  date: string;
  status: string;
  pk: string;
}

const PaymentPortal: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isShowingPaymentModal, setIsShowingPaymentModal] =
    React.useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = React.useState<string>("");

  const [isConfirmationPayModalOpen, setIsConfirmationPayModalOpen] =
    React.useState(false);
  const [portalData, setPortalData] = React.useState<PortalResponse | null>(
    null,
  );
  const [selectedPaymentType, setSelectedPaymentType] =
    React.useState<PaymentType>("payment");

  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get(`/payments/get/${id}`);
        setPortalData(response.data);
        console.log(response.data);
      } catch (error) {
        showAlert("Failed to fetch payments. Please try again later.", "error");
      } finally {
        setLoading(false);
      }
    };
    fetchPayments();
  }, [id, showAlert]);

  const handlePayClicked = (id: string, method: PaymentType) => {
    setSelectedPaymentId(id);
    setSelectedPaymentType(method);
    setIsShowingPaymentModal(true);
  };

  if (!portalData || loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const dues = portalData.dues || [];
  const otherPayments = portalData.other_payments || [];
  const payInFullDues = new Set(
    dues.filter((due) => due.pay_in_full_discount > 0).map((due) => due),
  );

  return (
    <>
      {id && (
        <PaymentChoiceModal
          userId={id}
          id={selectedPaymentId}
          isOpen={isShowingPaymentModal}
          setIsOpen={setIsShowingPaymentModal}
          openConfirmationPayModal={() => setIsConfirmationPayModalOpen(true)}
        />
      )}

      {id && (
        <ConfirmationPaymentModal
          dueOrPaymentId={selectedPaymentId}
          userId={id}
          isOpen={isConfirmationPayModalOpen}
          setIsOpen={setIsConfirmationPayModalOpen}
          paymentType={selectedPaymentType}
        />
      )}

      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography id="name-title" variant="h6" component="div">
            {portalData.payer_name
              ? `Payments for ${portalData.payer_name}`
              : ""}
          </Typography>
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg">
        <Box mt={4}>
          {dues.length === 0 && otherPayments.length === 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="50vh"
              textAlign="center"
            >
              <Card
                variant="outlined"
                sx={{ maxWidth: 400, padding: 4, borderRadius: 2 }}
              >
                <CardContent>
                  <PaymentIcon sx={{ fontSize: 50, color: "#4caf50", mb: 2 }} />
                  <Typography variant="h5" gutterBottom>
                    All Caught Up!
                  </Typography>
                  <Typography
                    id="done-text"
                    variant="body1"
                    color="textSecondary"
                  >
                    You have no pending payments.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <>
              {/* Dues Section */}
              {dues.map((dueInfo, i) => (
                <Box key={i} mb={5}>
                  <Typography
                    id={`due-name-${i + 1}`}
                    variant="h5"
                    gutterBottom
                  >
                    {dueInfo.name}
                  </Typography>
                  <Divider />
                  <Grid container spacing={3} mt={3}>
                    {payInFullDues.has(dueInfo) && dueInfo.can_pay_in_full && (
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Card
                          variant="outlined"
                          sx={{
                            borderRadius: 2,
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s",
                            "&:hover": {
                              transform: "scale(1.02)",
                            },
                          }}
                        >
                          <CardContent>
                            <Typography
                              id={`pay-in-full-discount-title-${i + 1}`}
                              variant="body1"
                              gutterBottom
                              sx={{
                                fontWeight: 500,
                                fontSize: "1rem",
                                color: "#333",
                              }}
                            >
                              Pay in Full Discount
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ marginBottom: 1 }}
                              id={`pay-in-full-amount-${i + 1}`}
                            >
                              Total Amount: $
                              {(
                                dueInfo.payments.reduce(
                                  (total, payment) =>
                                    total + parseFloat(payment.amount),
                                  0,
                                ) *
                                (1 - dueInfo.pay_in_full_discount)
                              ).toFixed(2)}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ marginBottom: 1 }}
                              id={`pay-in-full-date-${i + 1}`}
                            >
                              Date:{" "}
                              {new Date(
                                dueInfo.pay_in_full_date,
                              ).toLocaleDateString()}
                            </Typography>
                            <Button
                              id={`pay-in-full-btn-${i + 1}`}
                              onClick={() =>
                                handlePayClicked(dueInfo.pk, "due")
                              }
                              variant="contained"
                              fullWidth
                              sx={{
                                backgroundColor: "#4caf50", // Green for Pay in Full
                                color: "#fff",
                                "&:hover": {
                                  backgroundColor: "#388e3c", // Darker green on hover
                                },
                              }}
                            >
                              Pay In Full
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {dueInfo.payments.map((payment, j) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={payment.pk}>
                        <Card
                          variant="outlined"
                          sx={{
                            borderRadius: 2,
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s",
                            "&:hover": {
                              transform: "scale(1.02)",
                            },
                          }}
                        >
                          <CardContent>
                            <Typography
                              id={`payment-memo-${i + 1}-${j + 1}`}
                              variant="body1"
                              gutterBottom
                              sx={{
                                fontWeight: 500,
                                fontSize: "1rem",
                                color: "#333",
                              }}
                            >
                              {payment.memo}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ marginBottom: 1 }}
                              id={`payment-amount-${i + 1}-${j + 1}`}
                            >
                              Amount: ${payment.amount}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ marginBottom: 2 }}
                              id={`payment-date-${i + 1}-${j + 1}`}
                            >
                              Date:{" "}
                              {new Date(payment.date).toLocaleDateString()}
                            </Typography>
                            <Button
                              id={`payment-btn-${i + 1}-${j + 1}`}
                              onClick={() =>
                                handlePayClicked(payment.pk, "payment")
                              }
                              disabled={payment.status === "PENDING"}
                              variant="contained"
                              fullWidth
                              sx={{
                                backgroundColor: "#007bff", // Blue for normal payments
                                color: "#fff",
                                "&:hover": {
                                  backgroundColor: "#0056b3", // Darker blue on hover
                                },
                              }}
                            >
                              {payment.status === "PENDING"
                                ? "Pending Approval"
                                : "Pay"}
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}

              {/* Other Payments Section */}
              {otherPayments.length > 0 && (
                <Box mt={5} mb={5}>
                  <Typography
                    id="other-payments-title"
                    variant="h5"
                    gutterBottom
                  >
                    Other Payments
                  </Typography>
                  <Divider />
                  <Grid container spacing={3} mt={3}>
                    {otherPayments.map((payment, i) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={payment.pk}>
                        <Card
                          variant="outlined"
                          sx={{
                            borderRadius: 2,
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s",
                            "&:hover": {
                              transform: "scale(1.02)",
                            },
                          }}
                        >
                          <CardContent>
                            <Typography
                              id={`other-payment-memo-${i + 1}`}
                              variant="body1"
                              gutterBottom
                              sx={{
                                fontWeight: 500,
                                fontSize: "1rem",
                                color: "#333",
                              }}
                            >
                              {payment.memo}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ marginBottom: 1 }}
                              id={`other-payment-amount-${i + 1}`}
                            >
                              Amount: ${payment.amount}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ marginBottom: 2 }}
                              id={`other-payment-date-${i + 1}`}
                            >
                              Date:{" "}
                              {new Date(payment.date).toLocaleDateString()}
                            </Typography>
                            <Button
                              id={`other-payment-btn-${i + 1}`}
                              onClick={() =>
                                handlePayClicked(payment.pk, "payment")
                              }
                              disabled={payment.status === "PENDING"}
                              variant="contained"
                              fullWidth
                              sx={{
                                backgroundColor: "#007bff", // Blue for normal payments
                                color: "#fff",
                                "&:hover": {
                                  backgroundColor: "#0056b3", // Darker blue on hover
                                },
                              }}
                            >
                              {payment.status === "PENDING"
                                ? "Pending Approval"
                                : "Pay"}
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default PaymentPortal;
