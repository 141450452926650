import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import dayjs from "dayjs";
import { AlertContext } from "../../Context/AlertManager";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Member } from "../ManageMembers/ManageMembers";
import AlertDialogue from "../../Components/AlertDialogue";
import AddPayerModal from "./Components/AddPayerModal";

export interface DetailedDue {
  name: string;
  total_amount: number;
  due_date: string;
  payment_dates: string[];
  payment_amounts: number[];
  payers: Member[];
  pay_in_full_date: string;
  pay_in_full_discount: number;
  pk: number;
}

const DueDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [due, setDue] = useState<DetailedDue | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [isAddingPayer, setIsAddingPayer] = useState(false);

  const [pagination, setPagination] = useState({
    paymentPage: 0,
    paymentRowsPerPage: 5,
    payerPage: 0,
    payerRowsPerPage: 5,
  });

  const navigate = useNavigate();
  const { showAlert } = useContext(AlertContext);
  const theme = useTheme();

  useEffect(() => {
    const fetchDueData = async () => {
      try {
        const response = await axios.get(`/due/get/${id}`);
        const data: DetailedDue = response.data;
        setDue(data);
      } catch (error) {
        showAlert("Failed to load due details. Try refreshing.", "error");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchDueData();
    } else {
      navigate("/");
    }
  }, [id, navigate]);

  const handlePaymentChangePage = (event: unknown, newPage: number) => {
    setPagination((prev) => ({ ...prev, paymentPage: newPage }));
  };

  const handlePayerChangePage = (event: unknown, newPage: number) => {
    setPagination((prev) => ({ ...prev, payerPage: newPage }));
  };

  const handlePaymentChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPagination((prev) => ({
      ...prev,
      paymentRowsPerPage: newRowsPerPage,
      paymentPage: 0,
    }));
  };

  const handlePayerChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPagination((prev) => ({
      ...prev,
      payerRowsPerPage: newRowsPerPage,
      payerPage: 0,
    }));
  };

  // TODO: make the user confirm before removing a payer.
  const removePayerFromDue = async (member: Member) => {
    if (!due) {
      return;
    }

    try {
      await axios.post(`/due/${due.pk}/due-payers/delete/`, {
        payer_ids: [member.id],
      });

      const index = due.payers.findIndex((payer) => payer.id == member.id);
      const newDue = { ...due };
      newDue.payers.splice(index, 1);
      setDue(newDue);
    } catch (error) {
      showAlert("Failed to remove member. Please try again later.", "error");
    }
  };

  const addPayersToDue = async (members: Member[]) => {
    if (!due) {
      return;
    }

    const payer_ids = members.map((member) => member.id);
    try {
      const res = await axios.post(`due/${due.pk}/due-payers/add/`, {
        payer_ids,
      });
      setIsAddingPayer(false);
      const newDue = { ...due };
      newDue.payers = newDue.payers.concat(members);
      setDue(newDue);
      console.log(newDue.payers);
    } catch (error) {
      showAlert("Failed to add members. Please try again later.", "error");
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!due) {
    return <Typography variant="h6">Failed to load due details</Typography>;
  }

  const recentPayments = [] as any;

  return (
    <>
      <AddPayerModal
        onConfirm={addPayersToDue}
        isOpen={isAddingPayer}
        setIsOpen={setIsAddingPayer}
      />
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography id="due-title" variant="h5" gutterBottom>
                {due.name}
              </Typography>
              <Divider style={{ marginBottom: 16 }} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography id="due-amount" variant="h6" color="textPrimary">
                    Amount Due
                  </Typography>
                  <Typography variant="body1">${due.total_amount}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" color="textPrimary">
                    Due Date
                  </Typography>
                  <Typography variant="body1">
                    {dayjs(due.due_date).format("MMMM D, YYYY")}
                  </Typography>
                </Grid>

                {due.pay_in_full_discount && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="h6" color="textPrimary">
                        Pay in Full By
                      </Typography>
                      <Typography variant="body1">
                        {due.pay_in_full_date}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" color="textPrimary">
                        Discount
                      </Typography>
                      <Typography variant="body1">
                        {(due.pay_in_full_discount * 100).toFixed(2)}%
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" gutterBottom>
                  Members
                </Typography>
                <IconButton
                  onClick={() => setIsAddingPayer(true)}
                  color="primary"
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Box>

              <Divider />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: "4px 8px" }}>Names</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {due.payers
                      .slice(
                        pagination.payerPage * pagination.payerRowsPerPage,
                        pagination.payerPage * pagination.payerRowsPerPage +
                          pagination.payerRowsPerPage,
                      )
                      .map((payer, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ padding: "4px 8px" }}>
                            {payer.first_name} {payer.last_name}
                          </TableCell>
                          <TableCell sx={{ padding: "4px 8px" }}>
                            <IconButton
                              color="secondary"
                              onClick={() => removePayerFromDue(payer)}
                            >
                              <PersonRemoveIcon />
                            </IconButton>
                          </TableCell>{" "}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={due.payers.length}
                  rowsPerPage={pagination.payerRowsPerPage}
                  page={pagination.payerPage}
                  onPageChange={handlePayerChangePage}
                  onRowsPerPageChange={handlePayerChangeRowsPerPage}
                />
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Payment Schedules
              </Typography>
              <Divider />
              <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: "4px 8px" }}>
                        Payment Date
                      </TableCell>
                      <TableCell sx={{ padding: "4px 8px" }}>
                        Payment Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {due.payment_dates
                      .slice(
                        pagination.paymentPage * pagination.paymentRowsPerPage,
                        pagination.paymentPage * pagination.paymentRowsPerPage +
                          pagination.paymentRowsPerPage,
                      )
                      .map((date, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ padding: "4px 8px" }}>
                            {dayjs(date).format("MMMM D, YYYY")}
                          </TableCell>
                          <TableCell sx={{ padding: "4px 8px" }}>
                            $
                            {
                              due.payment_amounts[
                                pagination.paymentPage *
                                  pagination.paymentRowsPerPage +
                                  index
                              ]
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={due.payment_dates.length}
                  rowsPerPage={pagination.paymentRowsPerPage}
                  page={pagination.paymentPage}
                  onPageChange={handlePaymentChangePage}
                  onRowsPerPageChange={handlePaymentChangeRowsPerPage}
                />
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Recent Payments
              </Typography>
              <Divider />
              <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: "4px 8px" }}>
                        Payment Date
                      </TableCell>
                      <TableCell sx={{ padding: "4px 8px" }}>
                        Payment Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recentPayments.map(
                      (
                        payment: { date: string; amount: number },
                        index: number,
                      ) => (
                        <TableRow key={index}>
                          <TableCell sx={{ padding: "4px 8px" }}>
                            {dayjs(payment.date).format("MMMM D, YYYY")}
                          </TableCell>
                          <TableCell sx={{ padding: "4px 8px" }}>
                            ${payment.amount}
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default DueDetails;
