import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import React, { useState } from "react";
import { Payment } from "../../members/PaymentPortal/PaymentPortal";
import dayjs from "dayjs";

interface OutstandingPaymentTableProps {
  payments: Payment[];
}

export const OutstandingPaymentsTable: React.FC<
  OutstandingPaymentTableProps
> = ({ payments }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedPayments = payments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: "4px 8px" }}>Due</TableCell>
            <TableCell sx={{ padding: "4px 8px" }}>Amount</TableCell>
            <TableCell sx={{ padding: "4px 8px" }}>Date</TableCell>
            <TableCell sx={{ padding: "4px 8px" }}>Memo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedPayments.map((payment: Payment, index: number) => (
            <TableRow key={index}>
              <TableCell
                id={`${index + 1}-payment-due`}
                sx={{ padding: "4px 8px" }}
              >
                {payment.due ? payment.due : "-"}
              </TableCell>
              <TableCell
                id={`${index + 1}-payment-amount`}
                sx={{ padding: "4px 8px" }}
              >
                ${payment.amount}
              </TableCell>
              <TableCell
                id={`${index + 1}-payment-date`}
                sx={{ padding: "4px 8px" }}
              >
                {dayjs(payment.date).format("MMMM D, YYYY")}
              </TableCell>
              <TableCell
                id={`${index + 1}-payment-memo`}
                sx={{ padding: "4px 8px" }}
              >
                {payment.memo ? payment.memo : "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={payments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};
