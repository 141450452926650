import React from "react";
import { Button, Grid, Typography, Card, CardContent } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { PaymentSchedule, ScheduleManager } from "../../CreateDue";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import { AlertContext } from "../../../../Context/AlertManager";

interface DatesSlideProps {
  paymentSchedules: PaymentSchedule[];
  scheduleManager: ScheduleManager;
  handlePrevious: () => void;
  handleNext: () => void;
}

const ScheduleSlide: React.FC<DatesSlideProps> = ({
  paymentSchedules,
  scheduleManager,
  handlePrevious,
  handleNext,
}: DatesSlideProps) => {
  const [datesErrorText, setDatesErrorText] = React.useState<string>("");
  const [amountErrorText, setAmountErrorText] = React.useState<string>("");
  const [memoErrorText, setMemoErrorText] = React.useState<string>("");
  const { showAlert } = React.useContext(AlertContext);

  const totalSum = paymentSchedules.reduce(
    (acc, schedule) => acc + schedule.amount,
    0,
  );

  const handlePaymentsSubmit = () => {
    const paymentDates = paymentSchedules.map((schedule) => schedule.date);
    const paymentAmounts = paymentSchedules.map((schedule) => schedule.amount);
    const paymentMemos = paymentSchedules.map((schedule) => schedule.memo);

    const req = {
      payment_dates: paymentDates,
      payment_amounts: paymentAmounts,
      payment_memos: paymentMemos,
    };

    console.log(req);

    axios
      .post("/due/verify/", req)
      .then((res) => {
        handleNext();
      })
      .catch((err) => {
        setDatesErrorText("");
        setAmountErrorText("");

        if (err.response.status !== 400) {
          showAlert(
            "Failed to verify schedule. Please try again later.",
            "error",
          );
          return;
        }

        if ("payment_dates" in err.response.data) {
          let errorDescription = err.response.data.payment_dates[0];
          errorDescription =
            errorDescription.charAt(0).toUpperCase() +
            errorDescription.slice(1);
          setDatesErrorText(errorDescription);
        }

        if ("payment_amounts" in err.response.data) {
          let errorDescription = err.response.data.payment_amounts[0];
          errorDescription =
            errorDescription.charAt(0).toUpperCase() +
            errorDescription.slice(1);
          setAmountErrorText(errorDescription);
        }

        if ("payment_memos" in err.response.data) {
          let errorDescription = err.response.data.payment_memos[0];
          errorDescription =
            errorDescription.charAt(0).toUpperCase() +
            errorDescription.slice(1);
          setMemoErrorText(errorDescription);
        }
      });
  };

  const onAmountChange = (index: number, amount: string) => {
    if (amount === "") {
      scheduleManager.updateAmountForSchedule(index, 0);
      return;
    }

    const amt = parseFloat(amount);

    if (amt < 0 || isNaN(amt)) {
      return;
    }

    scheduleManager.updateAmountForSchedule(index, amt);
  };

  const onDateChange = (index: number, date: Dayjs) => {
    scheduleManager.updateDateForSchedule(index, date.format("YYYY-MM-DD"));
  };

  const onMemoChange = (index: number, memo: string) => {
    scheduleManager.updateMemoForSchedule(index, memo);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Create Payment Schedules
        </Typography>
      </Grid>

      {paymentSchedules.map((schedule: PaymentSchedule, index: number) => (
        <Grid key={index} item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <DatePicker
                    onChange={(newDate) =>
                      onDateChange(index, newDate as Dayjs)
                    }
                    defaultValue={dayjs(schedule.date)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    onChange={(e) => onAmountChange(index, e.target.value)}
                    value={schedule.amount}
                    id={`${index}-amount-entry`}
                    label="Amount"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    onChange={(e) => onMemoChange(index, e.target.value)}
                    value={schedule.memo}
                    id={`${index}-memo-entry`}
                    label="Memo"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <Button
                    id={`${index}-schedule-delete`}
                    onClick={() => scheduleManager.deleteDate(index)}
                  >
                    <DeleteOutlineIcon color="secondary" />
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Typography
          id="schedule-helper-text"
          variant="subtitle1"
          color="secondary"
        >
          {datesErrorText}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          id="amount-helper-text"
          variant="subtitle1"
          color="secondary"
        >
          {amountErrorText}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography id="memo-helper-text" variant="subtitle1" color="secondary">
          {memoErrorText}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={scheduleManager.addDate}
          startIcon={<AddCircleIcon />}
          color="primary"
          id="add-schedule"
        >
          Add Payment Schedule
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{ fontSize: "0.75rem" }}>Total: {totalSum}</Typography>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handlePrevious}
          sx={{ marginRight: 1 }}
          startIcon={<NavigateBeforeIcon />}
        ></Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePaymentsSubmit}
          endIcon={<NavigateNextIcon />}
          id="next"
        ></Button>
      </Grid>
    </Grid>
  );
};

export default ScheduleSlide;
