import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { AlertContext } from "../../../../Context/AlertManager";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import paymentMethods from "../utils/paymentMethods";
import { METHODS } from "http";
import { set } from "lodash";

export type PaymentType = "due" | "payment";

interface ConfirmationPaymentModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  dueOrPaymentId: string;
  userId: string;
  paymentType: PaymentType;
}

interface ConfirmationRequest {
  method: string;
  message: string;
  payer: string;
  due?: string;
  payment?: string;
}

export const ConfirmationPaymentModal: React.FC<
  ConfirmationPaymentModalProps
> = ({ isOpen, setIsOpen, dueOrPaymentId, userId, paymentType }) => {
  const [isCreating, setIsCreating] = React.useState<boolean>(false);
  const { showAlert } = React.useContext(AlertContext);
  const [paymentMethod, setPaymentMethod] = React.useState("");

  const onFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    formData.append("method", paymentMethod);
    const formEntries = Object.fromEntries((formData as any).entries());

    const requestBody: ConfirmationRequest = {
      method: formEntries["method"],
      message: formEntries["message"],
      payer: userId,
    };
    requestBody[paymentType] = dueOrPaymentId;

    setIsCreating(true);
    try {
      await axios.post(`/payments/confirmation/create/`, requestBody);
      setIsOpen(false);
      window.location.reload();
    } catch (e: any) {
      showAlert(
        "Failed to mark payment as paid. Please try again later.",
        "error",
      );
      console.error(e.response.data);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <form onSubmit={onFormSubmit}>
        <DialogTitle sx={{ mb: 2 }}>Payment Info</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="payment-method-label">Payment Method</InputLabel>
            <Select
              labelId="payment-method-label"
              id="method"
              name="method"
              value={paymentMethod}
              label="Payment Method"
              onChange={(e) => setPaymentMethod(e.target.value)}
              required
            >
              {paymentMethods.map((method) => (
                <MenuItem id={method.key} key={method.key} value={method.key}>
                  {method.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            id="message"
            name="message"
            label="Message"
            type="text"
            fullWidth
            variant="outlined"
            multiline={true}
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <LoadingButton id="submit" loading={isCreating} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
