import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PaymentRecord } from "../../Dashboard/Context/PaymentsProvider";

interface FulfilledPaymentsTableProps {
  records: PaymentRecord[];
}

export const FulfilledPaymentsTable: React.FC<FulfilledPaymentsTableProps> = ({
  records,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRecords = records.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: "4px 8px" }}>Date</TableCell>
            <TableCell sx={{ padding: "4px 8px" }}>Name</TableCell>
            <TableCell sx={{ padding: "4px 8px" }}>Amount</TableCell>
            <TableCell sx={{ padding: "4px 8px" }}>Description</TableCell>
            <TableCell sx={{ padding: "4px 8px" }}>Method</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRecords.map((record, index) => (
            <TableRow key={record.pk}>
              <TableCell
                id={`${index + 1}-record-date`}
                sx={{ padding: "4px 8px" }}
              >
                {record.date}
              </TableCell>
              <TableCell
                id={`${index + 1}-record-name`}
                sx={{ padding: "4px 8px" }}
              >{`${record.first_name} ${record.last_name}`}</TableCell>
              <TableCell
                id={`${index + 1}-record-amount`}
                sx={{ padding: "4px 8px" }}
              >
                ${record.amount}
              </TableCell>
              <TableCell
                id={`${index + 1}-record-description`}
                sx={{ padding: "4px 8px" }}
              >
                {record.description}
              </TableCell>
              <TableCell
                id={`${index + 1}-record-method`}
                sx={{ padding: "4px 8px" }}
              >
                {record.method}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={records.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};
