import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { createTheme, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import axios from "axios";
import { AlertProvider } from "./Context/AlertManager";
import { AuthProvider } from "./Context/AuthManager";
import DashboardLayout from "./Components/DashboardLayout";
import ManageMembers from "./Pages/ManageMembers/ManageMembers";
import ManageDues from "./Pages/ManageDues/ManageDues";
import CreateDue from "./Pages/CreateDue/CreateDue";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DueDetails from "./Pages/DueDetails/DueDetails";
import PaymentPortal from "./Pages/members/PaymentPortal/PaymentPortal";
import MemberDetail from "./Pages/MemberDetail/MemberDetail";
import ConfirmPayments from "./Pages/ConfirmPayments/ConfirmPayments";
import { PaymentsProvider } from "./Pages/Dashboard/Context/PaymentsProvider";

const API_URL = "https://duesmate.io/api";

function App() {
  // Configure API
  axios.defaults.baseURL = API_URL;

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1976d2",
      },
      secondary: {
        main: "#dc004e",
      },
      background: {
        default: "#fff",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AlertProvider>
            <AuthProvider>
              <Routes>
                <Route path="/login" element={<SignIn />} />
                <Route path="/pay/:id" element={<PaymentPortal />} />
                <Route
                  path="/*"
                  element={
                    <DashboardLayout>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <PaymentsProvider>
                              <Dashboard />
                            </PaymentsProvider>
                          }
                        />
                        <Route
                          path="/manage-members"
                          element={<ManageMembers />}
                        />
                        <Route path="/manage-dues" element={<ManageDues />} />

                        <Route path="/create-due" element={<CreateDue />} />
                        <Route
                          path="/due-details/:id"
                          element={<DueDetails />}
                        />
                        <Route
                          path="/member-details/:id"
                          element={<MemberDetail />}
                        />

                        <Route
                          path="/payments/review"
                          element={<ConfirmPayments />}
                        />
                      </Routes>
                    </DashboardLayout>
                  }
                />
              </Routes>
            </AuthProvider>
          </AlertProvider>
        </ThemeProvider>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
