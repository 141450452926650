import axios from "axios";
import { set } from "cypress/types/lodash";
import React, { createContext, useState, useEffect, useContext } from "react";
import { Payment } from "../../members/PaymentPortal/PaymentPortal";
import { AlertContext } from "../../../Context/AlertManager";

export interface PaymentRecord {
  description: string;
  first_name: string;
  last_name: string;
  amount: string;
  date: string;
  method: string;
  pk: number;
}

export type PeriodQueryParam = "7" | "30" | "90" | "365";
export type PeriodLabel = "Weekly" | "Monthly" | "Quarterly" | "Yearly";

export const labelToPeriod: Record<PeriodLabel, PeriodQueryParam> = {
  Weekly: "7",
  Monthly: "30",
  Quarterly: "90",
  Yearly: "365",
};

export const periodToLabel: Record<PeriodQueryParam, PeriodLabel> = {
  "7": "Weekly",
  "30": "Monthly",
  "90": "Quarterly",
  "365": "Yearly",
};

interface PaymentContextProps {
  period: PeriodQueryParam;
  setPeriod: (period: PeriodQueryParam) => void;
  payments: PaymentRecord[];
  isFetchingPayments: boolean;
}

export const PaymentContext = createContext<PaymentContextProps>({
  period: "7",
  setPeriod: (period) => {},
  payments: [],
  isFetchingPayments: true,
});

export const PaymentsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [period, setPeriod] = React.useState<PeriodQueryParam>("7");
  const [payments, setPayments] = React.useState<PaymentRecord[]>([]);
  const [isFetchingPayments, setIsFetchingPayments] = React.useState(false);
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    async function fetchPayments() {
      setIsFetchingPayments(true);
      try {
        const response = await axios.get(
          `/payments/records/get?period=${period}`,
        );
        setPayments(response.data);
      } catch (e) {
        showAlert(
          "Failed to fetch payment records. Please refresh and try again.",
          "error",
        );
        console.error(e);
      }
      setIsFetchingPayments(false);
    }

    fetchPayments();
  }, [period]);

  return (
    <PaymentContext.Provider
      value={{ period, setPeriod, payments, isFetchingPayments }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContext;
