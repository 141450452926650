import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import { AlertContext } from "../../../../Context/AlertManager";
import { Member } from "../../../ManageMembers/ManageMembers";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

interface AddPayersProps {
  payers: Member[];
  setPayers: React.Dispatch<React.SetStateAction<Member[]>>;
  handleRemovePayer: (id: number) => void;
  handleAddPayer: () => void;
  handlePrevious: () => void;
  handleNext: () => void;
}

const columns: GridColDef<Member[][number]>[] = [
  {
    field: "first_name",
    headerName: "First name",
    width: 200,
    editable: true,
  },
  { field: "last_name", headerName: "Last name", width: 200, editable: true },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    editable: true,
  },
  {
    field: "phone_number",
    headerName: "Phone Number",
    sortable: false,
    editable: true,
    width: 160,
  },
];

const AddPayersSlide: React.FC<AddPayersProps> = ({
  handlePrevious,
  handleNext,
  setPayers,
  payers,
}) => {
  const [members, setMembers] = React.useState<Member[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { showAlert } = React.useContext(AlertContext);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [payersErrorText, setPayersErrorText] = React.useState<string>("");

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get("/due-payers/get/")
      .then((res) => {
        setMembers(res.data);
      })
      .catch((err) => {
        showAlert("Failed to fetch members! Try reloading!", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    const ids = new Set(rowSelectionModel);
    const selectedMembers = members.filter((member) => ids.has(member.id));
    setPayers(selectedMembers);
  }, [rowSelectionModel]);

  const handlePayersSubmit = () => {
    setPayersErrorText("");

    if (payers.length === 0) {
      setPayersErrorText("At least one payer is required.");
      return;
    }

    axios
      .post("/due/verify/", { payers: payers.map((payer) => payer.id) })
      .then((res) => {
        handleNext();
      })
      .catch((err) => {
        if ("payers" in err.response.data) {
          let errorDescription = err.response.data.payers[0];
          errorDescription =
            errorDescription.charAt(0).toUpperCase() +
            errorDescription.slice(1);
          setPayersErrorText(errorDescription);
        }
      });
  };

  return (
    <Grid container spacing={2} style={{ height: "100%" }}>
      <Grid item xs={12}>
        <Typography variant="h5">Who should be paying this Due?</Typography>
      </Grid>

      <Grid item xs={12} style={{ flexGrow: 1 }}>
        <DataGrid
          rows={members}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 15]}
          loading={isLoading}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          id="payers-helper-text"
          variant="subtitle1"
          color="secondary"
        >
          {payersErrorText}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handlePrevious}
          sx={{ marginRight: 1 }}
          startIcon={<NavigateBeforeIcon />}
        ></Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePayersSubmit}
          endIcon={<NavigateNextIcon />}
          id="next"
        ></Button>
      </Grid>
    </Grid>
  );
};

export default AddPayersSlide;
