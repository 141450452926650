import * as React from "react";
import Box from "@mui/material/Box";
import { AuthContext } from "../../Context/AuthManager";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridSlots,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import axios from "axios";
import { AlertContext } from "../../Context/AlertManager";
import { Button } from "@mui/material";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AlertDialogue from "../../Components/AlertDialogue";
import AddMemberFormModal from "./Components/AddMemberFormModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ActionButtonDropdown from "../MemberDetail/Components/ActionButtonDropdown";

export interface Member {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

export default function ManageMembers() {
  const { showAlert } = React.useContext(AlertContext);

  const [members, setMembers] = React.useState<Member[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [isConfirmingDelete, setIsConfirmingDelete] =
    React.useState<boolean>(false);

  const [memberToDelete, setMemberToDelete] = React.useState<Member | null>(
    null,
  );

  const [isAddingMember, setIsAddingMember] = React.useState<boolean>(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get("/due-payers/get/")
      .then((res) => {
        setMembers(res.data);
      })
      .catch((err) => {
        showAlert("Failed to fetch members!", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getMemberFromGrid = (id: GridRowId): Member | undefined => {
    return members.find((member) => member.id === id);
  };

  const handleDeleteClick = (id: GridRowId) => {
    const member = getMemberFromGrid(id);
    if (!member) {
      console.error("Member not found!");
      showAlert("Failed to delete member.", "error");
      return;
    }
    setMemberToDelete(member);
    setIsConfirmingDelete(true);
  };

  const handleViewMore = (id: GridRowId) => {
    const member = getMemberFromGrid(id);

    if (!member) {
      console.error("Member not found!");
      showAlert("Failed to view member.", "error");
    }

    navigate(`/member-details/${id}`);
  };

  const deleteMember = () => {
    if (!memberToDelete) return;

    axios
      .delete(`/due-payers/delete/${memberToDelete.id}/`)
      .then(() => {
        setMembers((members) =>
          members.filter((member) => member.id !== memberToDelete.id),
        );
        showAlert(`${memberToDelete.first_name} deleted!`, "success");
      })
      .catch(() => {
        showAlert(`Failed to delete ${memberToDelete.first_name}!`, "error");
      })
      .finally(() => {
        setIsConfirmingDelete(false);
      });
  };

  const columns: GridColDef<(typeof members)[number]>[] = [
    {
      field: "first_name",
      headerName: "First name",
      width: 200,
      editable: true,
    },
    {
      field: "last_name",
      headerName: "Last name",
      width: 200,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      sortable: false,
      editable: true,
      width: 160,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <ActionButtonDropdown
            handleDeleteClick={() => handleDeleteClick(id)}
            handleViewDetailsClick={() => handleViewMore(id)}
          />,
        ];
      },
    },
  ];

  const EditToolbar = () => {
    const handleClick = () => {
      setIsAddingMember(true);
    };

    return (
      <GridToolbarContainer>
        <Button
          id="add-member"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          Add Member
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: "Export data" },
            button: { variant: "outlined" },
          }}
        />
      </GridToolbarContainer>
    );
  };

  const onUpdateRow = async (updatedMember: Member, originalMember: Member) => {
    // No need to check!
    if (_.isEqual(updatedMember, originalMember)) return updatedMember;

    try {
      const response = await axios.put(
        `/due-payers/update/${updatedMember.id}/`,
        updatedMember,
      );
      showAlert(`${updatedMember.first_name} updated!`, "success");
      return response.data; // Server may do some formatting so we return the response data!
    } catch (err: any) {
      showAlert(`Failed to update ${originalMember.first_name}!`, "error");
      return originalMember;
    }
  };

  const onMemberAdded = (newMember: Member) => {
    setMembers((members) => [...members, newMember]);
  };

  return (
    <>
      {isAddingMember && (
        <AddMemberFormModal
          onMemberAdded={onMemberAdded}
          open={isAddingMember}
          setOpen={setIsAddingMember}
        />
      )}
      {isConfirmingDelete && (
        <AlertDialogue
          open={isConfirmingDelete}
          onConfirm={deleteMember}
          onDeny={() => setIsConfirmingDelete(false)}
          title="Delete Member"
          message="Are you sure you want to delete this member? This action cannot be undone!"
        />
      )}
      <DataGrid
        disableRowSelectionOnClick
        autoHeight
        rows={members}
        columns={columns}
        sx={{ mt: 4 }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        loading={isLoading}
        pageSizeOptions={[5, 10, 15]}
        slots={{
          toolbar: EditToolbar as GridSlots["toolbar"],
        }}
        processRowUpdate={(updatedRow: Member, originalRow: Member) => {
          return onUpdateRow(updatedRow, originalRow);
        }}
      />
    </>
  );
}
