import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Title from "./Title";
import PaymentContext, { periodToLabel } from "../Context/PaymentsProvider";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Payments() {
  const { payments, period } = React.useContext(PaymentContext);

  const total_amount = payments.reduce(
    (acc, payment) => acc + parseFloat(payment.amount),
    0,
  );

  return (
    <React.Fragment>
      <Title>Total Payments</Title>
      <Typography component="p" variant="h4">
        $
        {total_amount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {periodToLabel[period]}
      </Typography>
    </React.Fragment>
  );
}
