import axios from "axios";
import { set } from "cypress/types/lodash";
import React, { createContext, useState, useEffect, useContext } from "react";
import { AlertContext } from "./AlertManager";
import { useNavigate } from "react-router-dom";

export interface Notification {
  id: number;
  message: string;
  route: string;
}

interface UserInfoRes {
  email: string;
  notifications: Notification[];
}

interface AuthContextProps {
  token: string | null;
  email: string | null;
  notifications: Notification[] | null;
  updateToken: (token: string) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  token: null,
  email: null,
  notifications: null,
  updateToken: (token: string) => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<Notification[] | null>(
    null,
  );
  const [isFetchingToken, setIsFetchingToken] = useState(true);
  const [isFetchingInfo, setIsFetchingInfo] = useState(true);
  const { showAlert } = useContext(AlertContext);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);

    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    setIsFetchingToken(false);
  }, []);

  useEffect(() => {
    async function fetchUserInfo() {
      if (!token) {
        return;
      }

      try {
        const res = await axios.get("/users/");
        const data: UserInfoRes = res.data;
        setEmail(data.email);
        setNotifications(data.notifications);
      } catch (e: any) {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          navigate("/login");
          return;
        }
        showAlert(
          `Error ${e.response.status}: There was an error fetching user info, please refresh.`,
          "error",
        );
        console.error(e);
      }
    }

    if (token) {
      fetchUserInfo();
    }

    setIsFetchingInfo(false);
  }, [token]);

  const updateToken = (token: string) => {
    setToken(token);
    localStorage.setItem("token", token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };

  return (
    <AuthContext.Provider value={{ token, updateToken, email, notifications }}>
      {!isFetchingToken && !isFetchingInfo && children}
    </AuthContext.Provider>
  );
};
