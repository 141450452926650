import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import { AlertContext } from "../../../../Context/AlertManager";

interface PaymentChoiceModalProps {
  id: string;
  userId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  openConfirmationPayModal: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide: React.FC<PaymentChoiceModalProps> = ({
  id,
  isOpen,
  userId,
  setIsOpen,
  openConfirmationPayModal,
}) => {
  const { showAlert } = React.useContext(AlertContext);

  const handleClose = (): void => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Select Payment Method</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Choose your desired payment method.
          </DialogContentText>
          <Box mt={2}>
            <Tooltip title="Manual payment that will be verified by an admin.">
              <Button
                id="confirmation-pay-btn"
                variant="contained"
                color="primary"
                onClick={openConfirmationPayModal}
                fullWidth
              >
                Confirmation Payment
              </Button>
            </Tooltip>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertDialogSlide;
