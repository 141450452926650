import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { AlertContext } from "../../../../Context/AlertManager";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

interface DiscountSlideProps {
  payInFullDate: string;
  setPayInFullDate: React.Dispatch<React.SetStateAction<string>>;
  payInFullDiscount: number;
  setPayInFullDiscount: React.Dispatch<React.SetStateAction<number>>;
  totalAmount: number;
  handleNext: () => void;
}

const DiscountSlide: React.FC<DiscountSlideProps> = ({
  payInFullDate,
  payInFullDiscount,
  setPayInFullDate,
  setPayInFullDiscount,
  totalAmount,
  handleNext,
}: DiscountSlideProps) => {
  const [discountError, setDiscountError] = React.useState<string>("");
  const [dateError, setDateError] = React.useState<string>("");
  const [payInFullDiscountInput, setPayInFullDiscountInput] =
    useState<string>("0");
  const { showAlert } = React.useContext(AlertContext);

  const handleSubmitDiscount = () => {
    setDiscountError("");

    if (payInFullDiscountInput === "") {
      setDiscountError("Discount cannot be empty");
      return;
    }

    // No discount!
    if (payInFullDiscountInput === "0") {
      setPayInFullDiscount(0);
      handleNext();
      return;
    }

    const value = Number(payInFullDiscountInput);

    if (isNaN(value)) {
      setDiscountError("Discount must be a number between 0 and 100");
      return;
    }

    if (value < 0 || value > 99) {
      setDiscountError("Discount must be a number between 0 and 100");
      return;
    }

    axios
      .post("/due/verify/", {
        pay_in_full_date: payInFullDate,
        pay_in_full_discount: value / 100,
      })
      .then((res) => {
        setPayInFullDiscount(value / 100);
        handleNext();
      })
      .catch((err) => {
        if ("pay_in_full_date" in err.response.data) {
          let errorDescription = err.response.data.pay_in_full_date[0];
          errorDescription =
            errorDescription.charAt(0).toUpperCase() +
            errorDescription.slice(1);
          setDateError(errorDescription);
        }
        if ("pay_in_full_discount" in err.response.data) {
          let errorDescription = err.response.data.pay_in_full_discount[0];
          errorDescription =
            errorDescription.charAt(0).toUpperCase() +
            errorDescription.slice(1);
          setDiscountError(errorDescription);
        }
      });
  };

  const handleDiscountDateChange = (date: Dayjs | null) => {
    if (!date) return;
    setPayInFullDate(date.format("YYYY-MM-DD"));
  };

  return (
    <Grid container gap={2} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          Want to offer a pay in full discount?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          sx={{ mt: 2 }}
          id="discount-percentage"
          label="%"
          type="text"
          fullWidth
          variant="outlined"
          value={payInFullDiscountInput}
          onChange={(event) => setPayInFullDiscountInput(event.target.value)}
          error={discountError !== ""}
          helperText={discountError}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="Pay In Full Date"
          onChange={(newDate) => handleDiscountDateChange(newDate)}
          slotProps={{
            textField: {
              id: "discount-date",
              error: dateError !== "",
              helperText: dateError,
            },
          }}
          defaultValue={dayjs()}
        />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <Button
          id="next"
          variant="contained"
          color="primary"
          onClick={handleSubmitDiscount}
        >
          <NavigateNextIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default DiscountSlide;
