// PageSlug.ts
export type StaticPageSlug =
  | "/"
  | "/login"
  | "/signup"
  | "/manage-members"
  | "/manage-dues"
  | "/create-due";

export type DynamicPageSlug = "/due-details/:id" | "/member-details/:id";

export type PageSlug = StaticPageSlug | DynamicPageSlug;

// PageSlug.ts
export function getPageTitle(slug: PageSlug) {
  const pageTitles: { [key: string]: string } = {
    "/": "Dashboard",
    "/manage-members": "Manage Members",
    "/login": "Login",
    "/signup": "Signup",
    "/manage-dues": "Manage Dues",
    "/create-due": "Create Dues",
    "/payments/review": "Confirm Payments",
    "/due-details/:id": "Due Details",
    "/member-details/:id": "Member Details",
  };

  if (slug.startsWith("/due-details/")) {
    return pageTitles["/due-details/:id"];
  }

  if (slug.startsWith("/member-details/")) {
    return pageTitles["/member-details/:id"];
  }

  return pageTitles[slug] || "Page Not Found";
}
