import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Chart from "./Components/Chart";
import Payments from "./Components/PaymentsTotal";
import PaymentsTable from "./Components/PaymentsTable";
import React from "react";
import PaymentContext from "./Context/PaymentsProvider";
import { Typography } from "@mui/material";

export default function Dashboard() {
  // All components here have access to the PaymentContext, see app.tsx.

  const { isFetchingPayments, payments } = React.useContext(PaymentContext);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
          }}
        >
          {isFetchingPayments ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : (
            <Chart />
          )}
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
          }}
        >
          {isFetchingPayments ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : (
            <Payments />
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {isFetchingPayments ? (
            <Skeleton variant="rectangular" width="100%" height={150} />
          ) : (
            <PaymentsTable />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
