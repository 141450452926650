import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Payment } from "../members/PaymentPortal/PaymentPortal";
import axios from "axios";
import { AlertContext } from "../../Context/AlertManager";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Typography,
} from "@mui/material";
import { OutstandingPaymentsTable } from "./Components/OutstandingPaymentsTable";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddPaymentModal from "./Components/AddPaymentModal";
import { PaymentRecord } from "../Dashboard/Context/PaymentsProvider";
import { FulfilledPaymentsTable } from "./Components/FufilledPaymentsTable";

interface MemberDetails {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  payments: Payment[];
  payment_records: PaymentRecord[];
}

const MemberDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [member, setMember] = React.useState<MemberDetails | null>(null);
  const { showAlert } = useContext(AlertContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [tableSlide, setTableSlide] = React.useState("1");
  const [isAddingPayment, setIsAddingPayment] = React.useState(false);

  const navigate = useNavigate();

  if (!id) {
    navigate("/");
  }

  const addMemberPayment = (payment: Payment) => {
    setMember((prevMember) => {
      if (!prevMember) return null;
      return {
        ...prevMember,
        payments: [...prevMember.payments, payment],
      };
    });
  };

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const response = await axios.get(`due-payer/get/${id}`);
        setMember(response.data);
      } catch (error) {
        showAlert("Faled to fetch member details", "error");
      }
      setIsLoading(false);
    };

    fetchMember();
  }, []);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!member) {
    return <Typography>Failed to fetch member</Typography>;
  }

  const handleSlideChange = (event: React.SyntheticEvent, newValue: string) => {
    setTableSlide(newValue);
  };

  return (
    <>
      {isAddingPayment && id && (
        <AddPaymentModal
          isOpen={isAddingPayment}
          handleClose={() => setIsAddingPayment(false)}
          name={`${member.first_name} ${member.last_name}`}
          id={id}
          addPayment={addMemberPayment}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h4">Member Details</Typography>
              <Divider />
              <Box mt={2}>
                <Typography id="member-name" variant="body1">
                  {member.first_name} {member.last_name}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography id="member-email" variant="body1">
                  {member.email}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography id="member-number" variant="body1">
                  {member.phone_number}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent style={{ textAlign: "center" }}>
              <Typography variant="h4">Total Due</Typography>
              <Divider />
              <Typography id="member-total-due" sx={{ margin: 1 }} variant="h6">
                ${member.payments.reduce((a, b) => a + parseFloat(b.amount), 0)}
              </Typography>
              <Button
                id="add-payment-button"
                onClick={() => setIsAddingPayment(true)}
                sx={{ margin: 1 }}
                variant="outlined"
              >
                Add Payment
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {
          // TODO: Show fufilled payments here!
        }
        <Grid item xs={12}>
          <Card>
            <TabContext value={tableSlide}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleSlideChange} aria-label="Payment Tabs">
                  <Tab
                    id="outstanding-payments-tab"
                    label="Outstanding Payments"
                    value="1"
                    aria-controls="outstanding-payments-panel"
                  />
                  <Tab
                    id="fulfilled-payments-tab"
                    label="Fulfilled Payments"
                    value="2"
                    aria-controls="fulfilled-payments-panel"
                  />
                </TabList>
              </Box>
              <TabPanel
                id="outstanding-payments-panel"
                value="1"
                aria-labelledby="outstanding-payments-tab"
              >
                <OutstandingPaymentsTable payments={member.payments} />
              </TabPanel>
              <TabPanel
                id="fulfilled-payments-panel"
                value="2"
                aria-labelledby="fulfilled-payments-tab"
              >
                <FulfilledPaymentsTable records={member.payment_records} />
              </TabPanel>
            </TabContext>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default MemberDetail;
